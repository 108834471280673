<template>
  <div>

    <div class="row mt-4">
      <div class="col-md-6 mb-4">
        <form ref="topAnchor"></form>
        <h3 class="mt-2">Auftraggeber/Kunde</h3>
        <div class="row" v-if="this.isCompany">
          <div class="col-md-4 mb-1">
            <small>Firma</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.name}}
          </div>
        </div>
        <div v-else>
          <div class="row mt-4" v-if="this.setAnrede || this.thirdStepData.salutation">
            <div class="col-md-4 mb-1">
              <small>Anrede/Titel</small>
            </div>
            <div class="col-md-8 mb-3">
              {{ this.setAnrede }} {{this.thirdStepData.titel}}
            </div>
          </div>
          <div class="row" v-if="this.thirdStepData.nachname || this.thirdStepData.vorname">
            <div class="col-md-4 mb-1">
              <small>Nachname/Vorname</small>
            </div>
            <div class="col-md-8 mb-3">
              {{this.thirdStepData.nachname}}, {{this.thirdStepData.vorname}}
            </div>
          </div>
        </div>

        <div class="row" v-if="this.thirdStepData.zusatz">
          <div class="col-md-4 mb-1">
            <small>Zusatz</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.zusatz}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.strasse || this.thirdStepData.hausnummer">
          <div class="col-md-4 mb-1">
            <small>Straße/Hausnummer</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.strasse}} {{this.thirdStepData.hausnummer}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.plz || this.thirdStepData.ort">
          <div class="col-md-4 mb-1">
            <small>Plz/Ort</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.plz}} {{this.thirdStepData.ort}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.plz || this.thirdStepData.ort">
          <div class="col-md-4 mb-1">
            <small>Land</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.mapCountryKey(this.thirdStepData.country)}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.telefonlLaenderkennung || this.thirdStepData.telefonVorwahl || this.thirdStepData.telefonRufnummer">
          <div class="col-md-4 mb-1">
            <small>Telefon (Geschäft)/(Privat)</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.telefonLaenderkennung}} {{this.thirdStepData.telefonVorwahl}} {{this.thirdStepData.telefonRufnummer}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.mobilLaenderkennung || this.thirdStepData.mobilVorwahl || this.thirdStepData.mobilRufnummer">
          <div class="col-md-4 mb-1">
            <small>Mobiltelefon</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.mobilLaenderkennung}} {{this.thirdStepData.mobilVorwahl}} {{this.thirdStepData.mobilRufnummer}}
          </div>
        </div>
        <div class="row" v-if="this.thirdStepData.faxLaenderkennung || this.thirdStepData.faxVorwahl || this.thirdStepData.faxRufnummer">
          <div class="col-md-4 mb-1">
            <small>Telefax (Geschäft)/(Privat)</small>
          </div>
          <div class="col-md-8 mb-3">
            {{this.thirdStepData.faxLaenderkennung}} {{this.thirdStepData.faxVorwahl}} {{this.thirdStepData.faxRufnummer}}
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-4">
        <div class="grey-bg p-3">
          <h3>Auftragsdaten</h3>
          <div class="row mt-4">
            <div class="col-md-4 mb-1">
              <small>Auftragsart</small>
            </div>
            <div class="col-md-8 mb-3">
              {{ this.setAuftragsTyp }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-1">
              <small>Fondsname</small>
            </div>
            <div class="col-md-8 mb-3">
              <strong>{{this.fondsdata.fondsname}}</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-1">
              <small>Laufzeit</small>
            </div>
            <div class="col-md-8 mb-3">
              {{formatDate(this.secondStepData.validUntil)}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-1">
              <small>Beauftr. Nominale</small>
            </div>
            <div class="col-md-8 mb-3">
              {{reFormatAmount(this.secondStepData.nominale)}} {{this.fondsdata.currency}}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-1">
              <small>Limit</small>
            </div>
            <div class="col-md-8 mb-3">
              {{reFormatAmount(this.secondStepData.limit)}} %
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-1">
              <small>Teilausführung</small>
            </div>
            <div class="col-md-8 mb-3" v-if="this.secondStepData.partialBrokerage === 'Ja'">
              Ja
            </div>
            <div class="col-md-8 mb-3" v-else>
              Nein
            </div>
          </div>
          <div class="row" v-if="this.secondStepData.division">
            <div class="col-md-4 mb-1">
              <small>Wunschstückelung</small>
            </div>
            <div class="col-md-8 mb-3">
              {{reFormatAmount(this.secondStepData.division)}} {{this.fondsdata.currency}}
            </div>
          </div>

          <b-form ref="checkboxForm">
            <div class="form-row checkbox mt-3">
              <div class="form-group col mb-1">
                  <b-checkbox
                      class="mb-3"
                      id="agbField"
                      v-model="$v.checkboxForm.agb.$model"
                      :state="validateState($v.checkboxForm.agb.$model)"
                      aria-describedby="agbInputField-live-feedback"
                  >
                    <div v-if="!this.loadingSubsiteContent">
                      <div v-html="this.auftragszusammenfassungAGB"></div>
                    </div>
                    <b-form-invalid-feedback id="agbInputField-live-feedback">
                      <span v-if="!$v.checkboxForm.agb.$model">Bitte stimmen Sie den AGBs zu.</span>
                    </b-form-invalid-feedback>
                  </b-checkbox>

                </div>
              </div>
              <div class="form-row checkbox mt-3">
                <div class="form-group col mb-1">
                  <b-checkbox
                      class="mb-3"
                      id="informationsField"
                      v-model="$v.checkboxForm.informations.$model"
                      :state="validateState($v.checkboxForm.informations.$model)"
                      aria-describedby="informationsInputField-live-feedback"
                  >
                  <div v-if="!this.loadingSubsiteContent">
                    <div v-html="this.auftragszusammenfassungBegleitdokumente"></div>
                  </div>
                    <b-form-invalid-feedback id="informationsInputField-live-feedback">
                      <span v-if="!$v.checkboxForm.informations.$model">Bitte stimmen Sie der Zusammenfassung der Begleitdokumente zu.</span>
                    </b-form-invalid-feedback>
                </b-checkbox>
              </div>
            </div>
            <div class="form-row checkbox mt-3">
              <div class="form-group col mb-1">
                <b-checkbox
                    class="mb-3"
                    id="disclaimerField"
                    v-model="$v.checkboxForm.disclaimer.$model"
                    :state="validateState($v.checkboxForm.disclaimer.$model)"
                    aria-describedby="disclaimerInputField-live-feedback"
                >
                  <div v-if="!this.loadingSubsiteContent">
                    <div v-html="this.auftragszusammenfassungWiderrufsbelehrung"></div>
                  </div>
                  <b-form-invalid-feedback id="disclaimerInputField-live-feedback">
                    <span v-if="!$v.checkboxForm.disclaimer.$model">Bitte stimmen Sie der Widerrufsbelehrung zu.</span>
                  </b-form-invalid-feedback>
                </b-checkbox>
              </div>
            </div>
          </b-form>

          <div class="alert alert-danger" v-if="error401">
            <p>
              Ihre Sitzung ist abgelaufen.
            </p>
          </div>

          <div class="alert alert-danger" v-if="error404 || error403 || error500">
            <p>
              Es ist ein Fehler aufgetreten.
            </p>
          </div>

          <div class="alert alert-danger" v-if="error409">
            <p>
              Der Fonds ist derzeit vom Handel ausgesetzt, bitte setzen Sie sich mit uns in Verbindung.
            </p>
          </div>

          <div class="alert alert-danger" v-if="error422">
            <p>
              Es liegt ein Validierungsfehler vor, bitte korrigieren Sie die fehlerhaften Felder.
            </p>
          </div>

          <div class="alert alert-danger" v-if="error406">
            <p>
              Dieser Auftrag kann nicht modifiziert werden.
            </p>
          </div>

          <div class="alert alert-danger" v-if="!this.submitSuccess">
            <p>
              Es ist ein Verarbeitungsfehler aufgetreten, dieser Auftrag kann nicht bearbeitet werden.
            </p>
          </div>

          <div v-if="orderLoading" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Ihr Auftrag wird bearbeitet...</strong>
          </div>
        </div>
      </div>

    </div>

    <div class="wizard-card-footer clearfix pl-0 pr-0">
      <div class="wizard-footer-left">
        <span role="button" tabindex="0">
          <button @click="goToPrevTab" class="btn btn btn-default btn-form btn-primary">Zurück</button>
        </span>
      </div>
      <div class="wizard-footer-right">
        <span role="button" tabindex="0">
          <button @click="onSubmit" class="btn btn btn-default btn-form btn-primary">Abschicken</button>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

import {validationMixin} from 'vuelidate';
import {required} from "vuelidate/lib/validators";
import moment from "moment";
import VueJwtDecode from "vue-jwt-decode";


export default {
  data() {
    return {
      loadingSubsiteContent: false,
      submitSuccess: true,
      subsiteContent: [],
      auftragszusammenfassungAGB: '',
      auftragszusammenfassungBegleitdokumente: '',
      auftragszusammenfassungWiderrufsbelehrung: '',

      checkboxForm: {
        agb: '',
        informations: '',
        disclaimer: '',
      },
      placeholders: ['%%username%%'],
      activatedCheckboxValidation: false,
    }
  },

  validations: {
      checkboxForm: {
        agb: {
          required,
        },
        informations: {
          required,
        },
        disclaimer: {
          required,
        },
      }
  },

  methods: {
    ...mapActions('auftragswizard', ['createOrder', 'editOrder']),
    textFieldHasData( field ) {
      return field !== undefined && field !== null && field.length > 0; 
    },
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if (token === '' || token === undefined) {
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected?contentTypes=32250,32260,32270';
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data.content;
            this.loadingSubsiteContent = false;
            this.auftragszusammenfassungAGB = this.replacePlaceholders( this.getHtmlContent(32250) );
            this.auftragszusammenfassungBegleitdokumente = this.replacePlaceholders( this.getHtmlContent(32260) );
            this.auftragszusammenfassungWiderrufsbelehrung = this.replacePlaceholders( this.getHtmlContent(32270) );
          })
          .catch(error => {
            console.log(error);
            this.loadingSubsiteContent = true;
          })
    },
    loadProfile() {
      this.$store.dispatch('user/loadUserProfile')
    },
    getProfile() {
      return this.userProfileData;
    },
    formatDate(date) {
      if (date === null) {
        console.error('Date cannot be null!');
        return null;
      }
      return moment(String(date)).format('DD.MM.YYYY');
    },
    reFormatAmount(amount) {
      if(amount !== undefined) {
        let splittedString = amount.split(',');
        let formattedString = amount.replace(',', '.');
        let newNumber = parseInt(formattedString);
        let fullAmountWithCurrency = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(newNumber);
        let fullAmountWithoutCurrency = fullAmountWithCurrency.slice(0, -5);
        if(splittedString[1] === undefined) {
          return fullAmountWithoutCurrency + ',00';
        } else {
          return fullAmountWithoutCurrency + ',' + splittedString[1];
        }
      }
    },
    formatAmount(number){
      let withoutDots = number.split('.').join('');
      let convertCommaToDot = withoutDots.replace(",", ".");
      return parseFloat(convertCommaToDot);
    },
    replacePlaceholders( htmlContent ) {
      var replacedHtmlContent = htmlContent;
      var token = this.$store.getters['auth/getToken'];
      var decodedToken = {
        sub: '',
        fullname: '',
        email: '',
      }
      if ( token ) {
        decodedToken = VueJwtDecode.decode(token);
      }
      for( let placeholderIx in this.placeholders ) {
        if ( '%%username%%' === this.placeholders[placeholderIx] ) {
          replacedHtmlContent = replacedHtmlContent.replace( this.placeholders[placeholderIx], decodedToken.fullname )
        }
      }
      return replacedHtmlContent;
    },
    getHtmlContent(contentType) {
      for (let item in this.subsiteContent) {
        var contentItem = this.subsiteContent[item];
        if (contentType === contentItem.contentType.id) {
          return contentItem.htmlcontent;
        }
      }
      return "No content for ID " + contentType;
    },
    validateState(validation) {
      if((this.$v.checkboxForm.agb.$dirty || this.$v.checkboxForm.informations.$dirty || this.$v.checkboxForm.disclaimer.$dirty) && this.activatedCheckboxValidation) {
        return !!(validation);
      }
    },
    goToPrevTab() {
      this.$emit('prevStep');
      if(!this.thirdStepData) {
        this.loadProfile();
      }
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
    onSubmit() {
      this.activatedCheckboxValidation = true;
      this.$v.$touch();
      if (this.$v.checkboxForm.agb.$model && this.$v.checkboxForm.informations.$model && this.$v.checkboxForm.disclaimer.$model) {
        this.submitAddress();
      }
    },
    submitAddress() {
      // submitAddress:
      var zweckForTyp = this.isCompany ? "Lieferadresse (Straße)" : "Postadresse privat";
      const payload = {
        zweck: zweckForTyp,
        standard: true,
        strasse: this.thirdStepData.strasse,
        hausnummer: this.thirdStepData.hausnummer,
        zusatz: this.thirdStepData.zusatz,
        plz: this.thirdStepData.plz,
        ort: this.thirdStepData.ort,
        land: this.thirdStepData.country,
      }
      var previousAddress = this.getProfile().anschrift;
      var different = !previousAddress ||
          payload.zweck !== previousAddress.zweck ||
          payload.standard !== previousAddress.standard ||
          payload.strasse !== previousAddress.strasse ||
          payload.hausnummer !== previousAddress.hausnummer ||
          payload.zusatz !== previousAddress.zusatz ||
          payload.plz !== previousAddress.plz ||
          payload.ort !== previousAddress.ort ||
          payload.land !== this.mapCountryValue( previousAddress.land )
      ;
      if (different) {
        this.$store.dispatch('user/saveAdressData', payload).then(responseAdress => {
          if (responseAdress !== null && responseAdress !== undefined) {
            if (responseAdress.status >= 400) {
              // Error message
              this.submitSuccess = false;
              console.log("Submit address failed");
            } else {
              alert();
              console.log("Submit address successful");
              this.submitPhone();
            }
          }
        })
      } else {
        this.submitPhone();
      }
    },
    submitPhone() {
      var zweckForTyp = this.isCompany ? "telefon_zentrale" : "telefon_privat";
      const payload = {
        typ: "TELEFON",
        zweck: zweckForTyp,
        standard: true,
        laenderkennung: this.thirdStepData.telefonLaenderkennung,
        vorwahl: this.thirdStepData.telefonVorwahl,
        rufnummer: this.thirdStepData.telefonRufnummer,
      }

      var different =
          payload.laenderkennung !== this.getProfile().telefon_laenderkennung ||
          payload.vorwahl !== this.getProfile().telefon_vorwahl ||
          payload.rufnummer !== this.getProfile().telefon_rufnummer;

      if (different) {
        this.$store.dispatch('user/saveTelefonData', payload).then(responsePhone => {
          if (responsePhone !== null && responsePhone !== undefined) {
            if (responsePhone.status >= 400) {
              // Error message
              this.submitSuccess = false;
              console.log("Submit phone number failed");
            } else {
              console.log("Submit phone number successful");
              this.submitMobile();
            }
          }
        })
      } else {
        this.submitMobile();
      }
    },
    submitMobile() {
      const payload = {
        typ: "TELEFON",
        zweck: "telefon_mobil",
        standard: true,
        laenderkennung: this.thirdStepData.mobilLaenderkennung,
        vorwahl: this.thirdStepData.mobilVorwahl,
        rufnummer: this.thirdStepData.mobilRufnummer,
      }

      var different =
          payload.laenderkennung !== this.getProfile().mobil_laenderkennung ||
          payload.vorwahl !== this.getProfile().mobil_vorwahl ||
          payload.rufnummer !== this.getProfile().mobil_rufnummer;

      if (different) {
        this.$store.dispatch('user/saveTelefonData', payload).then(responseMobile => {
          if (responseMobile !== null && responseMobile !== undefined) {
            if (responseMobile.status >= 400) {
              // Error message
              this.submitSuccess = false;
              console.log("Submit mobile number failed");
            } else {
              console.log("Submit mobile number successful");
              this.submitFax();
            }
          }
        })
      } else {
        this.submitFax();
      }
    },
    submitFax() {
      var zweckForTyp = this.isCompany ? "telefax_zentrale" : "telefax_privat";
      const payload = {
        typ: "FAX",
        zweck: zweckForTyp,
        standard: true,
        laenderkennung: this.thirdStepData.faxLaenderkennung,
        vorwahl: this.thirdStepData.faxVorwahl,
        rufnummer: this.thirdStepData.faxRufnummer,
      }

      var different =
          payload.laenderkennung !== this.getProfile().fax_laenderkennung ||
          payload.vorwahl !== this.getProfile().fax_vorwahl ||
          payload.rufnummer !== this.getProfile().fax_rufnummer;

      if (different) {
        this.$store.dispatch('user/saveFaxData', payload).then(responseFax => {
          console.log("response - saveFaxData mobile: ", responseFax);
          if (responseFax !== null && responseFax !== undefined) {
            if (responseFax.status >= 400) {
              // Error message
              this.submitSuccess = false;
              console.log("Submit fax number failed");
            } else {
              console.log("Submit fax number successful");
              if (this.isAuftragChange) {
                this.submitEditOrder();
              } else {
                this.submitCreateOrder();
              }
              console.log("Whole order process was successful")
              this.submitSuccess = true;
            }
          }
        })
      } else {
        if (this.isAuftragChange) {
          this.submitEditOrder();
        } else {
          this.submitCreateOrder();
        }
        console.log("Whole order process was successful")
        this.submitSuccess = true;
      }
    },
    submitCreateOrder() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const trancheId = urlParams.get('tid');
      const type = urlParams.get('auftragType');

      const partialBrokerage = this.stringToBoolean(this.secondStepData.partialBrokerage);

      const payload = {
        auftragArt: type,
        trancheId: parseInt(trancheId),
        nominale: {
          betrag: this.formatAmount(this.secondStepData.nominale),
          waehrung: this.fondsdata.currency
        },
        limit: this.formatAmount(this.secondStepData.limit),
        teilausfuehrbar: partialBrokerage,
        stueckelung: {
          betrag: this.formatAmount(this.secondStepData.division),
          waehrung: this.fondsdata.currency,
        },
        ablauf: this.formatDate(this.secondStepData.validUntil),
        agbGelesen: this.checkboxForm.agb,
        kundeninfoGelesen: this.checkboxForm.informations,
        widerrufGelesen: this.checkboxForm.disclaimer,
      }

      this.$store.dispatch('auftragswizard/createOrder', payload);
    },

    submitEditOrder() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const trancheId = urlParams.get('tid');
      const type = urlParams.get('auftragType');
      const nr = urlParams.get('auftragNr');
      const id = urlParams.get('id');

      const partialBrokerage = this.stringToBoolean(this.secondStepData.partialBrokerage);

      const payload = {
        id: parseInt(id),
        auftragNr: parseInt(nr),
        auftragArt: type,
        trancheId: parseInt(trancheId),
        nominale: {
          betrag: this.formatAmount(this.secondStepData.nominale),
          waehrung: this.fondsdata.currency
        },
        limit: this.formatAmount(this.secondStepData.limit),
        teilausfuehrbar: partialBrokerage,
        stueckelung: {
          betrag: this.formatAmount(this.secondStepData.division),
          waehrung: this.fondsdata.currency,
        },
        ablauf: this.formatDate(this.secondStepData.validUntil),
        agbGelesen: this.checkboxForm.agb,
        kundeninfoGelesen: this.checkboxForm.informations,
        widerrufGelesen: this.checkboxForm.disclaimer,
      }

      this.$store.dispatch('auftragswizard/editOrder', payload);
    },

    mapCountryKey( keySearch ) {
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].key === keySearch ) {
          return this.rawCountryData[item].value;
        }
      }
    },
    mapCountryValue( valueSearch ) {
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].value === valueSearch ) {
          return this.rawCountryData[item].key;
        }
      }
    },
    loadCountries() {
      return axios.get( this.$store.getters.getAllEnvis.API_LAENDER )
          .then(response => {
            this.rawCountryData = response.data;
            this.countries = [];
            // Map the raw data key:value (DE:Deutschland) to a format understood by the b-select component value:text (DE:Deutschland)
            for(let item in this.rawCountryData) {
              this.countries.push( { value: this.rawCountryData[item].key, text: this.rawCountryData[item].value } )
            }
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    stringToBoolean(string) {
      if(string === 'Ja') {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters('auftragswizard', ['fondsdata', 'auftragsTyp', 'secondStepData', 'thirdStepData', 'orderLoading', 'error401','error403','error404','error406','error409','error422','error500']),
    ...mapGetters('user', ['userProfileData']),

    isCompany() {
      if(this.userProfileData.typ === 'Firma') {
        return true;
      } else {
        return false;
      }
    },
    isAuftragChange() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const auftragNr = urlParams.get('auftragNr');
      if(auftragNr) {
        return true
      } else {
        return false
      }
    },
    setAuftragsTyp() {
      if (this.auftragsTyp === 'KAUF') {
        return 'Kaufauftrag';
      } else if (this.auftragsTyp === 'VERKAUF') {
        return 'Verkaufsauftrag';
      } else {
        return false;
      }
    },
    setAnrede() {
      if (this.thirdStepData.salutation === 'Herr') {
        return 'Herr';
      } else if (this.thirdStepData.salutation === 'Frau') {
        return 'Frau';
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadSubsiteContent();
  },
  created() {
    this.loadCountries();
  },
  mixins: [validationMixin],
}
</script>

<style type="scss" scoped>

</style>
